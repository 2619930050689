import { FluidObject, FixedObject } from "gatsby-image";

export const extractHtml = (data: any): { [key: string]: string } =>
    Object.keys(data)
        .filter(key => key in data && !!data[key])
        .reduce((obj, key) => {
            if (!!data[key].childMarkdownRemark) {
                return {
                    [key]: data[key].childMarkdownRemark.html,
                    ...obj,
                };
            } else if (!!data[key].html) {
                return {
                    [key]: data[key].html,
                    ...obj,
                };
            } else {
                return obj;
            }
        }, {});

export const extractImage = (
    data: any
): { [key: string]: FluidObject | FixedObject } =>
    Object.keys(data)
        .filter(key => key in data && !!data[key])
        .reduce((obj, key) => {
            if (!!data[key].childImageSharp) {
                return {
                    [key]:
                        data[key].childImageSharp.fixed ||
                        data[key].childImageSharp.fluid,
                    ...obj,
                };
            } else {
                return obj;
            }
        }, {});
