import React, { FunctionComponent } from "react";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import classnames from "classnames";
import classes from "./hero.module.css";
import { extractImage } from "../util";

const Hero: FunctionComponent<{ small?: boolean }> = ({ small = true }) => {
    const data = useStaticQuery(
        graphql`
          query {
            desktop: file(relativePath: { eq: "homepage.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      );

    const { desktop } = extractImage(data);

    return (
        <BackgroundImage
            Tag="section"
            className={classnames(classes.heroContainer, { [classes.small]: small })}
            fluid={desktop}
        >

            <h1 className={classes.title}>Praktijk De Leersprong</h1>
            <ul className={classes.services}>
                <li>Bijles</li>
                <li>Remedial teaching</li>
                <li>Huiswerkbegeleiding</li>
                <li>Cito-training</li>
            </ul>

        </BackgroundImage>
    );
};

export default Hero;